@use 'sass:list';

$headers: (
  h1: (
    default: (
      margin: 0,
      font-size: 111px,
      line-height: 1,
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  ),
  h2: (
    default: (
      margin: 0,
      font-size: 85px,
      line-height: 1,
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  ),
  h3: (
    default: (
      margin: 0,
      font-size: 64px,
      line-height: 1,
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  ),
  h4: (
    default: (
      margin: 0,
      font-size: 49px,
      line-height: 1.4,
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  ),
  h5: (
    default: (
      margin: 0,
      font-size: 38px,
      line-height: 1.4,
      letter-spacing: 0.76px
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  ),
  h6: (
    default: (
      margin: 0,
      font-size: (
        default: 22px,
        md: 29px
      ),
      line-height: (
        default: 1.5,
        md: 1.4
      ),
      letter-spacing: (
        default: 0.22px,
        md: 0.58px
      )
    ),
    regular: (
      font-family: 'Raleway',
      font-weight: 400
    ),
    semibold: (
      font-family: 'RalewaySemiBold',
      font-weight: 600
    )
  )
);

@each $headerTag, $headerTagValue in $headers {
  $selector: '';
  $defaultStyles: map-get($headerTagValue, 'default');

  @each $headerTypeName, $headerTypeValues in $headerTagValue {
    $selector: '';
    
    @if $headerTypeName == 'regular' {
      $selector: '#{$headerTag}, .#{$headerTag}--#{$headerTypeName}';
    } @else {
      $selector: '.#{$headerTag}--#{$headerTypeName}';
    }
    
    #{$selector} {
      @if $headerTypeName != 'default' {
        @each $defaultStyleName, $defaultStyleValue in $defaultStyles {
          @if list.length($defaultStyleValue) == 1 {
            #{$defaultStyleName}: $defaultStyleValue !important;
          } @else {
            @each $mobileId, $mobileValue in $defaultStyleValue {
              @if $mobileId == 'default' {
                #{$defaultStyleName}: $mobileValue !important;
              } @else {
                @include media-breakpoint-up($mobileId) {
                  #{$defaultStyleName}: $mobileValue !important;
                }
              }
            }
          }
        }

        @each $styleName, $styleValue in $headerTypeValues {
          @if list.length($styleValue) == 1 {
            #{$styleName}: $styleValue !important;
          } @else {
            @each $mobileId, $mobileValue in $styleValue {
              @include media-breakpoint-up($mobileId) {
                #{$styleName}: $mobileValue !important;
              }
            }
          }
        }
      }
    }
  }
}

@mixin regularWeight() {
  font-family: 'Raleway' !important;
  font-weight: 400 !important;
}

@mixin semiboldWeight() {
  font-family: 'RalewaySemiBold' !important;
  font-weight: 600 !important;
}

@mixin bodyBig() {
  margin: 0;
  font-size: 16px !important;
  line-height: 1.5 !important;
  letter-spacing: 0.16px !important;

  @include media-breakpoint-up(md) {
    font-size: 22px !important;
    line-height: 1.5 !important;
    letter-spacing: 0.22px !important;
  }
}

@mixin bodyNormal() {
  margin: 0;
  font-size: 20px !important;
  line-height: 1.5 !important;
  letter-spacing: 0.2px !important;
}

@mixin bodySmall() {
  margin: 0;
  font-size: 13px !important;
  line-height: 1 !important;
  letter-spacing: 1.3px !important;
}

@mixin caption() {
  margin: 0;
  font-size: 10px !important;
  line-height: 1 !important;
  letter-spacing: 1px !important;
}

.body-big {
  &--regular {
    @include bodyBig();
    @include regularWeight();
  }

  &--semibold {
    @include bodyBig();
    @include semiboldWeight();
  }
}

.body-normal {
  &--regular {
    @include bodyNormal();
    @include regularWeight();
  }

  &--semibold {
    @include bodyNormal();
    @include semiboldWeight();
  }
}

.body-small {
  &--regular {
    @include bodySmall();
    @include regularWeight();
  }

  &--semibold {
    @include bodySmall();
    @include semiboldWeight();
  }
}

.caption {
  &--regular {
    @include caption();
    @include regularWeight();
  }

  &--semibold {
    @include caption();
    @include semiboldWeight();
  }
}

@mixin paragraphsNoOutsideMargin() {
  p {
    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}