.call-to-action {
  .cmp-button {
    background-color: transparent;
    border-color: $color-accent;
    &:hover {
      background-color: transparent;
      border-color: $color-accent-darker;
    }
    &:focus { 
      border-color: $color-accent-darker;
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: transparent;
      border-color: $color-shade-1;
      & .cmp-button__text {
        color: $color-shade-2;
      }
    }
  }

}
