.teaser {
  $mobileHeight: 500px;
  $tabletHeight: 822px;
  $desktopHeight: 900px;
  $arrow-size: 30px;
  $arrow-border-width: 3px;
  $arrow-animation-duration: 2s;

  @include container();
  position: relative;
  height: $tabletHeight;
  overflow: hidden;
  background: linear-gradient(343deg, #114F73 46.02%, #5894B6 96.63%);

  &.full-image {
    #{$cmpTeaser}__image {
      width: 100vw;
      right: calc((100vw - 100%) / -2);
      max-width: 100vw;

      .cmp-image {
        aspect-ratio: auto;
      }
    }
  }

  &.h-fullscale {
    height: 100vh;

    &.cq-Editable-dom {
      height: $desktopHeight;
    }

    #{$cmpTeaser}__content {
      bottom: 100px;
    }

    #{$cmpTeaser}__image {
      bottom: 350px;

      @include media-breakpoint-up(md) {
        bottom: 300px;
      }

      @include media-breakpoint-up(lg) {
        left: unset;
        transform: none;
      }
    }
    
    #{$cmpTeaser}__content,
    #{$cmpTeaser}__image {
      @include media-breakpoint-up(lg) {
        bottom: calc(242px / $desktopHeight * 100%);
      }
    }
  }

  &.h-small {
    height: $mobileHeight;

    #{$cmpTeaser}__image {
      top: 110px;

      .cmp-image {
        max-width: 170px;

        @include media-breakpoint-up(lg) {
          max-width: none;
        }
      }
    }

    #{$cmpTeaser}__content,
    #{$cmpTeaser}__image {
      @include media-breakpoint-up(lg) {
        bottom: calc(101px / $mobileHeight * 100%);
      }
    }
  }

  #{$cmpTeaser} {
    position: relative;
    height: 100%;
    color: $white;

    &__content,
    &__image {
      position: absolute;
      bottom: calc(152px / $desktopHeight * 100%);
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: $grid-gap;
      bottom: 50px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: calc(2 / 3 * 100%);
      }
    }

    &__image {
      width: calc(300 / 1440 * 100vw);
      max-width: 300px;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-up(lg) {
        width: calc(210 / 1440 * 100vw);
        max-width: 210px;
        left: 100%;
        transform: translateX(-100%);
      }

      .cmp-image {
        width: 100%;
        height: auto;
        aspect-ratio: 210 / 286;
      }
    }

    &__title {
      @include semiboldWeight();
      font: normal normal 600 38px/1.4 "Raleway" !important;
      letter-spacing: 0.76px;
      text-shadow: 0 7.01547384262085px 15.58994197845459px rgba(0, 0, 0, 0.12);
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        font: normal normal 600 85px/1 "Raleway" !important;
      }
    }

    &__description {
        @include bodyBig();
        @include regularWeight();
        @include paragraphsNoOutsideMargin();
    }
  }

  a {
    pointer-events: none;
  }

  .arrow {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: $arrow-size;
    height: $arrow-size;
    border: solid $white;
    border-width: 0 $arrow-border-width $arrow-border-width 0;
    display: inline-block;
    padding: $arrow-border-width;
    cursor: pointer;
    animation: slide-down $arrow-animation-duration infinite;
  }
  
  @keyframes slide-down {
    0% {
      transform: translate(-50%, -10px) rotate(45deg);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, 10px) rotate(45deg);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -10px) rotate(45deg);
      opacity: 0;
    }
  }
}