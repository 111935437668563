.cmp-form {
	&#contact-us {
    .cmp-form-text {
      label {
        display: none;
      }

      input, textarea {
        @include bodyNormal();
        @include semiboldWeight();
        border: none;
        background: $white;
        color: $gray;
  
        &:hover {
          @include bodyNormal();
          @include semiboldWeight();
          border: none;
          background: $white;
          color: $gray;
        }
      }
    }

		.cmp-text,
    .cmp-form-options__field-description {
      font: normal normal 400 14px/18px Raleway;
      letter-spacing: 1.4px;
      color: $gray;
		}

		.cmp-form-button {
      display: flex;
      padding: 18px 36px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $cyanAzure;
			border: none;
			border-radius: 0;
		}

		&.invalid .custom-message {
			display: block;

			&:not(.custom-error-message) {
				display: none;
			}
		}

		.custom-error-message {
			display: block;
      padding-top: 12px;
      font-size: 12px;
			color: $error-form;
		}

		.custom-message {
			display: none;
		}
	}
}