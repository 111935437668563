$module: '.cmp-title';

@mixin sectionHeadline($color: $gray-950, $marginBottom: 64px) {
  #{$module} {
    margin-bottom: $marginBottom;
    color: $color;
    text-align: center;
    text-transform: uppercase;
  }
}

.regular {
  #{$module},
  #{$module}__text {
    @include regularWeight();
  }
}

.semibold {
  #{$module},
  #{$module}__text {
    @include semiboldWeight();
  }
}

.cmp-title__link {
  color: $color-accent;
  text-decoration: none;
  border: 0.125rem solid $color-transparent;
  border-radius: 0.25rem;
  &:hover {
    color: $color-accent-darker;
    text-decoration: underline;
    text-decoration-color: $color-accent-darker;
    text-decoration-thickness: 0.125rem;
  }
  &:focus {
    color: $color-accent;
    text-decoration: underline;
    border-color: $color-accent-lighter;
    outline: none;
    text-decoration-color: $color-accent-lighter;
    text-decoration-thickness: 0.125rem;
  }
}