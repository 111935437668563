@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-weight: $weight;
        font-family: #{$name};
        font-style: $style;
        src: url('resources/fonts/#{$file}.woff');
    }
}

@include fontface(Raleway, 'Raleway/Raleway-Regular', 400);
@include fontface(RalewaySemiBold, 'Raleway/Raleway-SemiBold', 600);