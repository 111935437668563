.two-cols-with-title-text {
  @include sectionHeadline($color: $seaBlue, $marginBottom: 32px);

  > #{$cmpContainer} > #{$aemGrid} {
    .two-cols-with-text {
      #{$aemGrid} {
        align-items: start;
      }
    }
  }
}