@mixin media-breakpoint-up($breakpoint, $breakpoints: $grid-breakpoints) {
  @if map-has-key($breakpoints, $breakpoint) {
    $min: map-get($breakpoints, $breakpoint);
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @warn "Uknown breakpoint: #{$breakpoint}.";
  }
}

@mixin media-breakpoint-down($breakpoint, $breakpoints: $grid-breakpoints) {
  @if map-has-key($breakpoints, $breakpoint) {
    $max: map-get($breakpoints, $breakpoint);
    @media (max-width: #{$max - 0.1}) {
      @content;
    }
  } @else {
    @warn "Uknown breakpoint: #{$breakpoint}.";
  }
}

@mixin container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints,
  $paddings: false
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
      @if ($paddings) {
        padding-right: calc((100vw - #{$container-max-width}) / 2 + 100px);
        padding-left: calc((100vw - #{$container-max-width}) / 2 + 100px);
      }
    }
  }
}


@mixin horizontalGap($size, $property: "padding", $sign: "positive") {
  $margin: map-get($container-child-margins, child-margin-#{$size});
  @if $sign == "negative" {
    $margin: -$margin;
  }
  @if $property == "margin" {
    margin-left: $margin;
    margin-right: $margin;
  } @else if $property == "padding" {
    padding-left: $margin;
    padding-right: $margin;
  }
}

@mixin containerSize() {
  max-width: 350px;
  width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 794px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1120px;
  }
}

@mixin container($fullwidth: false) {
  @if not $fullwidth {
    padding: 0 16px;

    #{$cmpContainer},
    .cmp-teaser,
    > .aem-Grid {
      @include containerSize();
      margin: 0 auto;
    }
  }
}