@import 'src/components/navigation/_navigation.scss';

.header {
  --hamburger-menu-width: 40px;
  --hamburger-menu-height: 20px;
  --hamburger-menu-line-height: 5px;
  --hamburger-menu-color: #{$color-background};
  --hamburder-menu-border: var(--hamburger-menu-line-height) solid var(--hamburger-menu-color);
  --blue-filter-color: brightness(0) saturate(100%) invert(51%) sepia(5%) saturate(3661%) hue-rotate(158deg) brightness(106%) contrast(89%);

  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  &.fixed {
    --hamburger-menu-color: #{$lightBlue};

    position: fixed;
    background: $color-background;

    .image img {
      filter: var(--blue-filter-color);
    }

    @include media-breakpoint-up(lg) {
      .cmp-navigation__item--level-0 > .cmp-navigation__group {
        background: $color-background;
      }
    
      .cmp-navigation__item-link {
        color: $lightBlue;
      }
    }
  }

  #main-header {
    @include container();
    padding-top: 17px;
    padding-bottom: 17px;
  
    > .aem-Grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
			width: auto;
  
      &::before,
      &::after {
        content: none;
      }

      .image {
        width: 174px;
        height: 70px;
        margin: 0;
      
        .cmp-image {
          margin: 0;
        }
      }

      .navigation {
        width: auto;
      }
    }
  }

  .xf-content-height {
    min-height: auto;
  }
}

@include media-breakpoint-down(lg) {
  nav {
    > .cmp-navigation__group {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background: linear-gradient(to bottom, #a3c0cf, #eee);
      transform: translateY(-100%);
      transition: transform 1s ease-in-out;
    }

    .cmp-navigation__group {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;

      > .cmp-navigation__item {
        margin: 0;
        padding: 25px 0;

        &--level-0 {
          &:not(:last-child)::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100px;
            border-bottom: 1px solid $color-background;
            transform: translateX(-50%);
            opacity: 0.5;

            @include media-breakpoint-up(sm) {
              width: 200px;
            }
          }

          > .cmp-navigation__group {
            padding-top: 25px;
          }
        }

        &--level-0 {
          &:first-child {
            padding-top: 100px;
          }

          &:last-child {
            padding-bottom: 45px;
          }
        }

        &--level-1:last-child {
          padding-bottom: 0;
        }
      }
    }

    .cmp-navigation__item-link {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 20px;
      color: $color-background;
      display: block;
      text-align: center;
      letter-spacing: 0.2em;
    }
  }

  .nav-hamburger-menu {
    &::before,
    &::after {
      content: '';
      cursor: pointer;
    }

    &::before {
      position: absolute;
      width: var(--hamburger-menu-width);
      height: calc((var(--hamburger-menu-height) / 2) + (var(--hamburger-menu-line-height) / 2));
      border-bottom: var(--hamburder-menu-border);
    }

    &::after {
      position: relative;
      display: block;
      width: var(--hamburger-menu-width);
      height: var(--hamburger-menu-height);
      background: transparent;
      border-top: var(--hamburder-menu-border);
      border-bottom: var(--hamburder-menu-border);
    }
  }

  .nav-open {
    .header {
      &.fixed {
        --hamburger-menu-color: #{$color-background};
      }

      #main-header > .aem-Grid {
        .image {
          visibility: hidden;
        }
          
        .navigation .nav-hamburger-menu {
          display: block;
          margin: 0 5px 10px 0;
          transform: rotate(-45deg);

          &::before {
            width: calc((var(--hamburger-menu-width) / 2) - (var(--hamburger-menu-line-height) / 2));
            height: var(--hamburger-menu-width);
            border-right: var(--hamburder-menu-border);
            border-bottom: none;
          }

          &::after {
            width: var(--hamburger-menu-width);
            height: calc((var(--hamburger-menu-width) / 2) - (var(--hamburger-menu-line-height) / 2));
            border-top: none;
            border-bottom: var(--hamburder-menu-border);
          }
        }
      }
    }

    nav {
      > .cmp-navigation__group {
      transform: translateY(0%);
      }
    }
  }
}


.nav-revert {
  &.nav-open .header {
    --hamburger-menu-color: #{$color-background};
  }
  
  .header {
    --hamburger-menu-color: #{$lightBlue};
  
    position: relative;
  
    &.fixed {
      position: fixed;
    }
  
    .image img {
      filter: var(--blue-filter-color);
    }
  
    @include media-breakpoint-up(lg) {
      .cmp-navigation__item--level-0 > .cmp-navigation__group {
        background: $color-background;
      }
    
      .cmp-navigation__item-link {
        color: $lightBlue;
      }
    }
  }
}