.cmp-legal-page {
  #{$aemGrid} {
    display: flex;
    flex-direction: column;
    row-gap: 32px;

    &::before,
    &::after {
      content: none;
    }

    h1, h2, h3, h4, h5, h6 {
      @include semiboldWeight();
      color: $cyanAzure;
      margin-bottom: 20px !important;
    }

    h4 {
      margin-bottom: 32px !important;
    }

    p {
      @include bodyBig();
      @include regularWeight();
      color: $darkSilver;
    }
  }

  .table-wrapper {
    --border: 1px solid #eeeeee;
    max-width: 100%;
    overflow: auto;

    table, th, td {
      border: var(--border);
      border-collapse: collapse;
    }

    table {
      margin-bottom: 30px;
  
      > * {
        &:first-child tr {
          background: $black;
          color: $white;
        }
  
        td {
          padding: 1em;
        }
      }
    }
  }

  a,
  a:hover,
  a:focus {
    color: $cyanAzure !important;
    text-decoration: none !important;
  }
}