.cmp-our-portfolio {
  @include sectionHeadline($marginBottom: 0);

  > #{$cmpContainer} > #{$aemGrid} {
    display: flex;
    flex-direction: column;
    row-gap: 64px;

    @include media-breakpoint-up(sm) {
      row-gap: 32px;
    }

    &::before,
    &::after {
      content: none;
    }
  }

  .title + #{$container} {
    #{$aemGrid} {
      display: grid;
      grid-template-columns: 0 12fr 0;
      gap: $grid-gap;

      @include media-breakpoint-up(sm) {
        grid-template-columns: 1fr 10fr 1fr;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 2fr 8fr 2fr;
      }

      .cmp-text {
        @include bodyNormal();
        @include regularWeight();
        color: $gray-600;
        text-align: center;
      }
    }

    + #{$container} #{$aemGrid} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 35px;

      @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        grid-template-rows: none;
        align-items: center;
        gap: calc(2 * $grid-gap);
      }

      .contentfragment {
        width: 200px;
      }

      &::before,
      &::after {
        content: none;
      }
    }
  }

  &__item {
    $item: &;
    display: flex;

    &--logo {
      display: block;
      width: 100%;

      &-hover {
        display: none;
        width: 100%;
      }
    }

    &:hover {
      #{$item}--logo {
        display: none;

        &-hover {
          display: block;
        }
      }
    }

    img {
      margin: 0 auto;
      max-width: 100%;
    }
  }

  .button {
    display: flex;

    .cmp-button {
      margin: 0 auto;
    }
  }
}