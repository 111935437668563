
.primary-over-background {
  .cmp-button {
    background-color: transparent;
    color: $color-background;
    border-color: $color-background;
    &:hover {
      background-color: rgba($color-background, 0.1);
      border-color: $color-background;
      & .cmp-button__text {
        color:  $color-background;
      }
    }
    &:focus {
      background-color: rgba($color-background, 0.1);
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: transparent;
      border-color: rgba($lightBlue, 0);
      & .cmp-button__text {
        color: $color-shade-3;
      }
    }
  }

  .cmp-button__text {
    color: $color-text-inverted;
  }
}
