.cmp-our-portfolio-grid {
  $self: &;

  &__buttons {
    #{$cmpContainer} {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 20px;

      @include media-breakpoint-up(lg) {
        gap: 35px;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        max-width: 125px;
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 12px;
        background: $white;
        box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.10);

        @include media-breakpoint-up(md) {
          max-width: 160px;
        }

        @include media-breakpoint-up(lg) {
          max-width: 180px;
        }

        &:hover {
          .cmp-our-portfolio__item--logo {
            display: none;

            &-hover {
              display: block;
            }
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      width: 100%;
      height: auto;
      display: block;
    }

    &--info {
      scroll-margin-top: 103px;
      padding-bottom: 0 !important;

      #{$cmpContainer} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-logo {
        width: auto;
        height: 130px;
      }

      &-title,
      &-link {
        color: $cyanAzure;
        text-align: center;
        text-transform: uppercase;
      }

      &-title {
        font-size: 22px !important;

        @include media-breakpoint-up(lg) {
          font-size: 30px !important;
        }
      }

      &-link {
        margin-top: 35px;
        padding: 18px 36px;
        border-radius: 25px;
        font: normal normal 700 18px/1 Raleway;
        letter-spacing: 2px;
        color: $white;
        background: $cyanAzure;
        text-decoration: none;
      }
    }

    &--columns {
      padding-bottom: 150px !important;

      &.reverse-order {
        #{$cmpContainer} {
          grid-template-columns: none;
          grid-template-rows: 1fr;

          @include media-breakpoint-down(lg) {
            img {
              order: 0;
            }

            .separator {
              order: 1;
            }

            .text {
              order: 2;
            }
          }

          @include media-breakpoint-up(lg) {
            grid-template-columns: 6fr 1fr 5fr;
            grid-template-rows: none;
          }
        }
      }

      #{$cmpContainer} {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: 1fr;
        align-items: center;
        justify-items: center;
        gap: $grid-gap;

        @include media-breakpoint-up(lg) {
          grid-template-columns: 5fr 1fr 6fr;
          grid-template-rows: none;
        }

        img {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: 75%;
          }

          @include media-breakpoint-up(lg) {
            width: 100%;
          }
        }

        .cmp-text {
          @include bodyBig();
          @include regularWeight();
          color: $darkSilver;
        }
      }
    }
  }
}