.cmp-navigation__group {
  display: none;
  list-style: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    column-gap: $grid-gap;
  }
}

.cmp-navigation__item--level-0 {
  position: relative;
  display: block;
  margin-top: 25px;

  @include media-breakpoint-up(lg) {
    &:hover {
      & > .cmp-navigation__group {
        padding-top: 12px;
        padding-bottom: 25px;
        top: 86px;
        visibility: visible;
        opacity: 1;
      }
    }

    & > .cmp-navigation__group {
      @include container-max-widths($paddings: true);
      position: fixed;
      padding: 0;
      right: 50%;
      top: 74px;
      text-align: right;
      visibility: hidden;
      opacity: 0;
      width: 100%;
      overflow: hidden;
      transition: all 0.5s ease-out 0.5s;
      transform: translateX(50%);
    }
  }
}
  
.cmp-navigation__item-link {
  font: normal normal 600 15px/1 Raleway;
  letter-spacing: 1.5px;
  color: $white;
  text-transform: uppercase;
  text-decoration: none;
}