.cmp-text-video {
	> .cmp-container {
		> .aem-Grid {
			display: grid;
			row-gap: 20px;

			@include media-breakpoint-up(lg) {
				grid-template-columns: 4fr 8fr;
				column-gap: 20px;
			}

			&::before,
			&::after {
				content: unset;
			}

			.title {
				text-transform: uppercase;
				letter-spacing: 0.1em;

				.cmp-title {
					margin: 0;

					.cmp-title__text {
						font-weight: 700;
					}
				}
			}

			.embed iframe,
			.mixed-block video {
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
			}

			.mixed-block .cmp-platform-video {
				margin: 0;
			}
		}
	}
}