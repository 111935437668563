.cmp-form-text {
  display: grid;

  .error-message,
  .constraint-message {
    display: none;
    padding-top: 12px;
    font-size: 12px;
    color: $error-form;
  }

  &.error .error-message,
  &.constraint .constraint-message {
    display: block;
  }

  & label {
    margin-bottom: 10px;
    font-weight: 600;

    &.required:after {
      content: '*';
      margin-left: 3px;
      font-size: 22px;
      color: $error-form;
    }
  }
}

.cmp-form-text__textarea {
  height: 103px;
  padding: 10px 32px;
  font-size: 1em;
  background-color: $color-shade-1;

  &:hover {
    background-color: $color-background;
  }

  &:focus {
    border-color: $color-accent-lighter;
  }

  &:active {
    color: $color-text;
  }
}

.cmp-form-text__text {
  height: 30px;
  padding: 10px 32px;
  font-size: 1em;
  background-color: $color-shade-1;

  &:hover {
    background-color: $color-background;
  }

  &:focus {
    border-color: $color-accent-lighter;
  }

  &:active {
    color: $color-text;
  }
  //&:invalid {
  //  border-color: $error;
  //  color: $color-text;
  //}
  //&:valid {
  //  border-color: $success;
  //  color: $color-text;
  //}
}
