.cmp-investment-areas {
  @include sectionHeadline();

  > #{$cmpContainer} {
    #{$cmpContainer} > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $grid-gap;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
      }
  
      &::before,
      &::after {
        content: none;
      }

      .cmp-image {
        position: relative;

        &__image {
          aspect-ratio: 1 / 1;
        }

        &__title {
          position: absolute;
          bottom: 16px;
          left: 50%;
          width: calc(100% - 32px);
          color: $white;
          text-align: center;
          text-shadow: 0 2.596226453781128px 1.298113226890564px rgba(0, 0, 0, 0.15);
          text-transform: uppercase;
          transform: translateX(-50%);
          font: normal normal 600 16px/1.5 "RalewaySemiBold";
          letter-spacing: 0.16px;

          @include media-breakpoint-up(lg) {
            font: normal normal 600 22px/1.5 "RalewaySemiBold";
            letter-spacing: 0.22px;
          }
        }
      }
    }
  }
}