.cmp-categories {
  overflow: hidden;
  img {
    width: 67px;
  }

  .cmp-title {
    margin-top: 34px;
  }
}
