.separator {
  &.m-sm{
    hr {
      margin-top: $m-sm;
    }
  } 

  &.m-md{
    hr {
      margin-top: $m-md;
    }
  } 
  &.m-lg{
    hr {
      margin-top: $m-lg;
    }
  } 

  &.no-line hr {
    background-color: transparent;
  }
}
.cmp-separator__horizontal-rule {
  background-color: $color-shade-1;
  border: none;
}


