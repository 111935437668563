.cmp-banner {
  @include sectionHeadline($color: $black, $marginBottom: 0);
  
  .cmp-text > * {
    color: $black;
    font-size: 29px !important;
    line-height: 1.4 !important;
    letter-spacing: 0.58px !important;

    @include media-breakpoint-up(md) {
      font-size: 38px !important;
      letter-spacing: 0.76px !important;
    }
  }

  &.theme-white {
    @include sectionHeadline($color: $cyanAzure, $marginBottom: 0);
    
    .cmp-text > * {
      @include regularWeight();
      color: $cyanAzure;
    }
  }

  &.theme-gray,
  &.theme-blue {
    @include sectionHeadline($color: $white, $marginBottom: 0);
    
    .cmp-text > * {
      @include semiboldWeight();
      color: $white;
    }
  }
  
  &.theme-light-blue {
    @include sectionHeadline($color: $blue-600, $marginBottom: 0);
    
    .cmp-text > * {
      @include regularWeight();
      color: $blue-600;
    }
  }

  #{$aemGrid} {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    text-align: center;

    &::before,
    &::after {
      content: none;
    }
  }
}