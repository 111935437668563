.cmp-investment-focus-area {
  --icon-path: url("./resources/images/focus-area-icon.png");

  > #{$cmpContainer} {
    scroll-margin-top: 103px;
  }

  #{$container} {
    &:first-child {
      #{$aemGrid} {
        &::before,
        &::after {
          content: none;
        }
      }

      > #{$cmpContainer} > #{$aemGrid} {
        display: flex;
        flex-direction: column;
        row-gap: 50px;

        @include media-breakpoint-up(md) {
          row-gap: 150px;
        }

        @include media-breakpoint-up(lg) {
          row-gap: 200px;
        }

        &::before,
        &::after {
          content: none;
        }

        .title {
          .cmp-title__text {
            font: normal normal 600 49px/1.4 "Raleway" !important;
            color: $seaBlue;
            text-align: center;
            text-transform: uppercase;

            @include media-breakpoint-up(md) {
              font: normal normal 600 85px/1 "Raleway" !important;
            }
          }
        }

        .cmp-text-image {
          &:nth-of-type(even) {
            @include media-breakpoint-down(md) {
              .text {
                order: 0;
              }
  
              .separator {
                order: 1;
              }
  
              .image {
                order: 2;
              }
            }
          }

          #{$aemGrid} {
            display: grid;
            grid-template-columns: none;
            grid-template-rows: 1fr;
            align-items: center;
            gap: $grid-gap;

            @include media-breakpoint-up(md) {
              grid-template-columns: 5fr 1fr 6fr;
              grid-template-rows: none;
            }

            .cmp-text {
              @include bodyBig();
              @include regularWeight();
              color: $darkSilver;
            }
          }

          + #{$container}:not(.cmp-text-image) {
            > #{$cmpContainer} > #{$aemGrid} {
              display: flex;
              flex-direction: column;
              row-gap: 38px;

              .title {
                .cmp-title__text {
                  font: normal normal 600 29px/1.4 "Raleway" !important;
                  letter-spacing: 0.58px !important;
                  color: $cyanAzure;
                }
              }

              #{$aemGrid} {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                column-gap: $grid-gap;
                row-gap: 60px;
                padding-top: 36px;

                @include media-breakpoint-up(md) {
                  grid-template-columns: repeat(3, 1fr);
                }

                .button {
                  $icons: icon-calm-stress, icon-eco-bottle, icon-fulltech,
                    icon-help-planet-darkblue, icon-help-planet-lightblue, icon-lightning,
                    icon-recycling, icon-sleep, icon-tech;

                  @each $icon in $icons {
                    &.#{$icon} {
                      --icon-path: url("./resources/images/#{$icon}.png");
                    }
                  }

                  .cmp-button {
                    position: relative;
                    width: 100%;
                    min-height: 230px;
                    height: 100%;
                    padding: 36px 32px;
                    background: rgba(81, 137, 171, 0.13);
                    border: none;
                    border-radius: 0;
                    pointer-events: none;
  
                    &::before {
                      content: '';
                      position: absolute;
                      top: -36px;
                      width: 72px;
                      height: 72px;
                      background: var(--icon-path) no-repeat;
                      background-size: 72px;
                    }
  
                    &__text {
                      @include bodyNormal();
                      @include regularWeight();
                      color: $seaBlue;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}