.cmp-contact-us {
  .teaser +.image {
    .cmp-image {
      height: 500px;

      &__image {
        height: 100%;
      }
    }
  }

  .image {
    .cmp-image {
      position: relative;

      @include media-breakpoint-up(lg) {
        height: auto;
      }
  
      &__title {
        @include containerSize();
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
        column-gap: $grid-gap;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: 100%;
        font: normal normal 600 49px/1.4 Raleway;
        color: $white;
        text-align: center;
  
        &::before,
        &::after {
          content: '';
        }
      }
    }

    & + #{$container} {
      padding: 128px 0 !important;

      @include media-breakpoint-up(lg) {
        padding: 210px 0 177px 0 !important;
      }

      #{$aemGrid} {
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
        column-gap: $grid-gap;
        text-align: center;

        .cmp-text {
          color: $blue-300;
        }
      }

      & + #{$container} {
        > #{$cmpContainer} {
          padding: 100px 0;
          background: $blue-200;

          > #{$aemGrid} {
            display: grid;
            grid-template-columns: 1fr 10fr 1fr;
            column-gap: $grid-gap;

            #contact-us {
              display: flex;
              flex-direction: column;
              row-gap: 32px;
              
              &::before,
              &::after {
                content: none;
              }

              > #{$container} {
                #{$aemGrid} {
                  display: grid;
                  grid-template-columns: none;
                  grid-template-rows: 1fr;
                  column-gap: $grid-gap;
                  row-gap: 32px;

                  @include media-breakpoint-up(md) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: none;
                  }
  
                  &::before,
                  &::after {
                    content: none;
                  }
                }

                & + #{$container} {
                  #{$aemGrid} {
                    display: flex;
                    flex-direction: column;
                    row-gap: 32px;
    
                    &::before,
                    &::after {
                      content: none;
                    }
                  }
                }
              }
            }
          }
        }

        & + #{$container} {
          padding: 62px 0 !important;

          @include media-breakpoint-up(md) {
            padding: 148px 0 186px 0 !important;
          }

          #{$aemGrid} {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            row-gap: 20px;

            .text {
              &:first-child {
                .cmp-text {
                  @include bodyBig();
                  @include regularWeight();
                  color: $blue-600;
  
                  p {
                    margin: 6px 0;
                  }
                }
              }

              &:last-child {
                .cmp-text {
                  @include bodySmall();
                  @include semiboldWeight();
                  color: $blue-600;
                  text-decoration: none;
                }
              }
            }

            .image {
              width: 100%;

              @include media-breakpoint-up(md) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}