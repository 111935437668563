.quiet {
  .cmp-button {
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    &:hover {
      background-color: $color-shade-1;
      border-color: $color-shade-1;
    }
    &:focus {
      background-color: $color-shade-1;
      border-color: $color-shade-1;
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: $color-background;
      border-color: $color-background;
      & .cmp-button__text {
        color: $color-shade-2;
      }
    }
  }
  .cmp-button__text {
    color: $color-shade-4;
  }
}
