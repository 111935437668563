@use 'sass:list';

$spacings: (
  none: 0,
  small: (
    default: 64px,
    md: 100px
  ),
  medium: (
    default: 100px,
    md: 140px
  ),
  large: (
    default: 100px,
    md: 200px
  )
);

@each $spacingSize, $spacingValue in $spacings {
  .p-#{$spacingSize} {
    @if list.length($spacingValue) == 1 {
      padding-top: $spacingValue !important;
      padding-bottom: $spacingValue !important;
    } @else {
      @each $mobileId, $mobileValue in $spacingValue {
        @if $mobileId == 'default' {
          padding-top: $mobileValue !important;
          padding-bottom: $mobileValue !important;
        } @else {
          @include media-breakpoint-up($mobileId) {
            padding-top: $mobileValue !important;
            padding-bottom: $mobileValue !important;
          }
        }
      }
    }
  }
}