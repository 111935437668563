.cmp-video {
  @include media-breakpoint-up(lg) {
    padding-bottom: 150px !important;
  }

  &.play {
    .image {
      display: none;
    }

    .cmp-video {
      padding-bottom: 0 !important;
    }
  }

  &:not(.play) {
    .embed {
      display: none;
    }
  }

  .image {
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: url("./resources/images/play.png") no-repeat center center;
      background-size: 61px;
      filter: drop-shadow(0 3.812499761581421px 28.59375px rgba(0, 0, 0, 0.25));
      z-index: 1;

      @include media-breakpoint-up(sm) {
        background-size: 90px;
      }

      @include media-breakpoint-up(md) {
        background-size: 138px;
      }
      
      @include media-breakpoint-up(lg) {
        background-size: 195px;
      }
    }
  }

  .cmp-image__image,
  iframe {
    aspect-ratio: 26 / 15;
    border: none;
  }

  .cmp-image__title {
    position: absolute;
    top: 0;
    padding: 30px calc(100vw * 44 / 390) 0;
    color: $white;
    font: normal normal 600 16.366px/1 Raleway;
    letter-spacing: 1.637px;
    text-transform: uppercase;
    text-shadow: 0 1.1933677196502686px 1.1933677196502686px rgba(0, 0, 0, 0.25);

    @include media-breakpoint-up(md) {
      padding: 97px calc(100vw * 140 / 1440) 0;
      font-size: 52.286px;
      letter-spacing: 5.229px;
      text-shadow: 0 3.812499761581421px 3.812499761581421px rgba(0, 0, 0, 0.25);
    }
  }
}