.cmp-pmi-accesses {
  @include sectionHeadline($gray);

  > #{$cmpContainer} {
    #{$container} #{$aemGrid} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
        gap: 52px $grid-gap;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      &::before,
      &::after {
        content: none;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-transform: uppercase;
      }

      .text {
        display: grid;
        grid-template-columns: none;
        grid-template-rows: auto 16px;
        gap: 16px;
        text-align: center;

        &::after {
          content: '';
          background: $blue-500;
        }

        @include media-breakpoint-up(md) {
          grid-template-columns: 16px auto;
          grid-template-rows: none;
          text-align: left;
        
          &::before {
            content: '';
            background: $blue-500;
          }
          
          &::after {
            content: none;
          }
        }
        
        .cmp-text {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          
          > * {
            @include semiboldWeight();
            color: $gray-950;
          }

          p {
            @include bodyNormal();
            @include regularWeight();
            color: $gray-600;
          }
        }
      }
    }
  }
}