.section-headline {
  --section-headline-color: #{$color-text};

  .cmp-title__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 17px;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: var(--section-headline-color);
    text-transform: uppercase;

    &::after {
      content: '';
      width: 43px;
      height: 5px;
      background: var(--section-headline-color);
      display: inline-flex;
    }
  }
}