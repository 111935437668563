.cmp-team-member {
  .cmp-teaser {
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__content {
      position: relative;
      bottom: 0;
      text-align: center;

      p {
        margin: 0;
      }
    }

    &__description {
      text-shadow: 0px 7.015px 15.59px rgba(0, 0, 0, 0.12);
    }
  }

  .no-fullwidth #{$cmpContainer} {
    position: relative;
    
    #{$aemGrid} {
      position: absolute;
      top: -200px;
      margin-bottom: 160px;
      padding: 128px 0 32px;
      background: $white;
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
    }
  } 

  &__item {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    gap: $grid-gap;
    padding: 0 16px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 3fr 1fr 6fr 1fr;
      grid-template-rows: none;
    }

    &::before,
    &::after {
      content: '';
    }

    &-section {
      position: relative;

      &:last-child {
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        align-items: end;
      }
    }

    &--image {
      margin-bottom: 20px;
      background: $blue-100;

      img {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
      }
    }

    &--timeline {
      p {
        margin: 0;

        &:nth-child(3n + 1) {
          @include bodyNormal();
          @include semiboldWeight();
          color: $gray-700;
          margin-bottom: 4px;
        }

        &:nth-child(3n + 2) {
          font: normal normal 600 18px/1.5 "Raleway" !important;
          letter-spacing: 0.18px !important;
          color: $blue-500;
        }

        &:nth-child(3n + 3) {
          font: normal normal 400  16px/1.5 Raleway;
          letter-spacing: 0.16px;
          color: $philippineGray;
          margin-bottom: 8px;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    &--description {
      @include bodyNormal();
      @include regularWeight();
      color: $darkSilver;

      p {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      + a {
        @include bodyNormal();
        @include semiboldWeight();
        padding: 18px 36px;
        color: $cyanAzure;
        text-decoration: none;
      }
    }
  }

  &__separator,
  &__navigation {
    display: grid;
    column-gap: $grid-gap;

    &::before,
    &::after {
      content: '';
    }
  }

  &__separator {
    grid-template-columns: 1fr;
    margin: 32px 0;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 10fr 1fr;
    }

    span {
      width: 100%;
      height: 1px;
      background: $blue-500;
    }
  }

  &__navigation {
    grid-template-columns: 0fr 6fr 6fr 0fr;
    align-items: center;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 5fr 5fr 1fr;
    }

    &-link--left {
      text-align: left;
    }

    &-link--right {
      text-align: right;
    }

    a {
      @include bodyBig();
      @include semiboldWeight();
      padding: 15px 0;
      color: $cyanAzure;
      text-decoration: none;
    }
  }
}
