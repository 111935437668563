html,
body {
  margin: 0;
  background: $color-background;
  color: $color-text;
  font-size: 16px;
  font-family: Raleway, serif;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1;
  letter-spacing: 0.1em;
}

body.nav-open {
  @include media-breakpoint-down(lg) {
    overflow: hidden;
  }
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@include media-breakpoint-down("md"){     
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}