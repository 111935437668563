.cmp-text-two-images {
  padding-top: 60px;
  padding-bottom: 50px;

  .title {
    padding-bottom: 20px;
  }
  .cmp-title {
    margin-top: 0;
    line-height: 42.26px;
  }
  > .cmp-container > .aem-Grid {
    display: grid;
    row-gap: 0;
    &::before,
    &::after {
      content: unset;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(12, 1fr);
      column-gap: 60px;
      > .container {
        &:first-child {
          grid-column: span 6;
        }
        &:nth-child(2) {
          grid-column: span 6;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      > .container {
        &:first-child {
          grid-column: span 6;
        }
        &:nth-child(2) {
          grid-column: span 6;
        }
      }
    }
  }

  .image {
    &:first-child {
      margin-top: 40px;
      img {
        width: 80%;
      }
    }
    &:nth-child(2) {
      > div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      img {
        width: 80%;
        margin-top: -25px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .image {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
