.cmp-button {
  display: flex;
  padding: 10px 37px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #C7C7C7;
  background: $white;
  text-decoration: none;
  cursor: pointer;

  &__text {
    text-align: center;
    color: $gray;
    font: normal normal 500 14px/1 Raleway;
    letter-spacing: 1.4px;
    text-transform: uppercase;
  }
}