.cmp-form-button {
  float: right;
  width: fit-content;
  height: fit-content;
  font-weight: 600;
  color: $color-text-inverted;
  background-color: $color-accent;
  border: 0.125em solid $color-accent;
  border-radius: 1.56em;
  text-transform: uppercase;

  &:hover {
    background-color: $color-accent-darker;
    border-color: $color-accent-darker;
    cursor: pointer;
  }
  &:focus {
    background-color: $color-accent-lighter;
    border-color: $color-background;
    outline: none;
    box-shadow: 0 0 0 0.125em $color-accent-lighter;
  }
  &:disabled {
    background-color: $color-shade-1;
    border-color: $color-shade-1;
  }
}
