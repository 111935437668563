.cmp-our-team {
  .cmp-contentfragmentlist {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 32px;
    column-gap: $grid-gap;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    .cmp-our-team__item {
      text-decoration: none;

      &:hover {
        .cmp-our-team__item--image {
          background-color: $blue-200;
        }
      }

      &--image {
        display: flex;
        justify-content: center;
        width: 100%;
        height: auto;
        aspect-ratio: 360 / 265;
        background-color: $blue-100;
        margin-bottom: 8px;
      }

      &--name,
      &--job-title {
        display: flex;
      }

      &--name {
        color: $lightBlue;
      }

      &--job-title {
        font-size: 16px !important;
        letter-spacing: 0.16px !important;
        color: $gray;
      }
    }
  }
}
