.quiet-over-background {
  .cmp-button {
    color:$color-background;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    &:hover {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      & .cmp-button__text {
        color: $color-shade-2;
      }
    }
    &:focus {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      outline: none;
      box-shadow: 0 0 0 0.125em $color-accent-lighter;
    }
    &:disabled {
      background-color: rgba(0, 0, 0, 0);
      border-color: rgba(0, 0, 0, 0);
      & .cmp-button__text {
        color: $color-shade-3;
      }
    }
  }
  .cmp-button__text {
    color: $color-text-inverted;
  } 
}
