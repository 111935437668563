.container {
  @include container(true);

  &.no-fullwidth {
    @include container();
  }
  
  &.main {
    padding: 0;
  }
}