$theme: (
  white: (
    background: $white,
    color: inherit
  ),
  gray: (
    background: $gray-50,
    color: $gray-600
  ),
  dark-gray: (
    background: $brightGray,
    color: $philippineGray
  ),
  blue: (
    background: $blue-500,
    color: $white
  ),
  light-blue: (
    background: $blue-100,
    color: $white
  )
);

@each $themeType, $themeValues in $theme {
  .theme-#{$themeType + ''} {
    @each $styleName, $styleValue in $themeValues {
      #{$styleName}: $styleValue;
    }
  }
}