.cmp-text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.1em;
  color: $color-text;

  & b {
    font-weight: bold;
  }

  & p a {
    color: $color-accent;
    text-decoration: none;
    border: 0.125em solid $color-transparent;
    border-radius: 0.25em;

    &:hover {
      color: $color-accent-darker;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent-darker;
      text-decoration-style: solid;
    }

    &:focus {
      text-decoration: underline;
      border-color: $color-accent;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: $color-accent;
      text-decoration-style: solid;
    }
  }

  p,
  blockquote,
  pre,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  & blockquote {
    font-size: 1.75em;
    font-family: Raleway, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }

  & pre {
    text-wrap: wrap;
  }

  & ul li::marker {
    content: '-  ';
  }
}