#main-footer {
  @include container();
  padding: 79px 0 15px;
  background-color: $color-footer-black;
  color: $color-background;

  #{$aemGrid} {
    &::before,
    &::after {
      content: none;
    }
  }

  > #{$aemGrid} {
    display: flex;
    flex-direction: column;
    row-gap: 40px;

    > .container {
      padding: 0 16px;

      @include media-breakpoint-up(md) {
        padding: 0;
      }

      &:first-child {
        > #{$cmpContainer} > #{$aemGrid} {
          display: grid;
          grid-template-columns: none;
          grid-template-rows: 1fr;
          gap: 75px;

          @include media-breakpoint-up(md) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: none;
          }

          > #{$container} {
            &:last-child > #{$cmpContainer} > #{$aemGrid} {
              #{$aemGrid} {
                grid-template-columns: auto 34px;
                justify-content: end;
                column-gap: 15px;
              }
            }

            > #{$cmpContainer} > #{$aemGrid} {
              display: flex;
              flex-direction: column;
              row-gap: 16px;
  
              .cmp-title__text {
                @include bodySmall();
                @include semiboldWeight();
                color: $white;
              }

              > .text {
                p {
                  display: flex;
                  flex-direction: column;
                  row-gap: 16px;

                  br {
                    display: none;
                  }

                  a {
                    font: normal normal 400 14px/1 Raleway;
                    letter-spacing: 1.4px;
                    color: $white;
                    text-decoration: none;
                  }
                }
              }

              .cmp-button {
                margin-top: 25px;
                border-radius: 2px;
                border: 1px solid $white;
                box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
                background: transparent;

                @include media-breakpoint-up(md) {
                  margin-top: 175px;
                }

                &__text {
                  @include bodySmall();
                  @include semiboldWeight();
                  color: $white;
                }
              }

              #{$aemGrid} {
                display: grid;
                grid-template-columns: 13px auto;
                column-gap: 8px;

                .cmp-text {
                  font: normal normal 400 14px/1 Raleway;
                  letter-spacing: 1.4px;
                  color: $white;
                }
              }
            }
          }
        }
      }

      &:last-child {
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 100%;
          height: 1px;
          background: $color-accent;
        }

        > #{$cmpContainer} {
          padding-top: 18px;
        }

        #{$aemGrid} {
          display: grid;
          grid-template-columns: none;
          grid-template-rows: 1fr;
          justify-items: center;
          align-items: center;
          row-gap: 25px;

          @include media-breakpoint-up(md) {
            grid-template-columns: 181px auto;
            grid-template-rows: none;
            justify-content: space-between;
          }

          > .image {
            width: 181px;
          }

          p, a {
            @include bodySmall();
            @include regularWeight();
            color: $white;
            text-decoration: none;
          }
        }
      }
    }
  }
}
