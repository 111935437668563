.cmp-portfolio-product-item {
  padding-top: 55px;
  padding-bottom: 40px;

  .contentfragment,
  .list {
    display: none;
  }

  article {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @include media-breakpoint-up(md) {
      grid-template-columns: 7fr 5fr;
    }
  }

  .product-item {
    &-content {
      display: inline-block;
    }

    &__logo {
      width: 180px;
      height: auto;
      margin-bottom: 5px;
    }

    &__title {
      --section-headline-color: #{$lightBlue};
      
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.1em;
      color: var(--section-headline-color);
      text-transform: uppercase;

      &::after {
        content: '';
        width: 43px;
        height: 5px;
        background: var(--section-headline-color);
      }
    }

    &__description {
      margin-bottom: 50px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: $color-text;
      }
    }

    &__button {
      padding: 14px 40px;
      text-decoration: none;
    }

    &-images {
      display: flex;
      flex-direction: column;
      gap: 15px;

      img,
      .product-item__half-image {
        width: 100%;
        height: auto;
      }

      .product-item__half-image {
        aspect-ratio: 240 / 222;
        background-size: cover;
        background-position: center;
      }
    }

    &__horizontal-images {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    &__nav {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
      align-items: center;
      margin-top: 80px;

      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
      }

      .nav-item {
        display: inline-flex;
        align-items: center;
        column-gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: #BEBEBE;
        text-decoration: none;
        text-transform: uppercase;

        &::before,
        &::after {
          width: 10px;
          height: 18px;
        }

        &.prevEl {
          text-align: left;

          &::before {
            content: "";
            background-image: url("./resources/images/arrow-left-gray.png");
          }
        }

        &.nextEl {
          text-align: right;
          justify-content: flex-end;

          &::after {
            content: "";
            background-image: url("./resources/images/arrow-left-gray.png");
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}