.primary {
    .cmp-button {
      box-shadow: none;
      color: $color-background;
      background-color: $lightBlue;
      border-color: $lightBlue;
      &:hover {
        background-color: lighten($lightBlue, 10%);
        border-color: $color-shade-1;
      }
      &:focus {
        background-color: $color-shade-1;
        border-color: $lightBlue;
        outline: none;
        box-shadow: 0 0 0 0.125em $color-accent-lighter;
      }
      &:disabled {
        background-color: $color-background;
        border-color: $color-background;
        & .cmp-button__text {
          color: $color-shade-2;
        }
      }
    }
}