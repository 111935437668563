.two-cols-with-text {
  #{$aemGrid} {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr;
    column-gap: 80px;
    row-gap: 40px;
    align-items: center;
    text-align: center;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: none;
      justify-content: start;
      text-align: left;
    }

    &::before,
    &::after {
      content: none;
    }

    .cmp-text {
      p {
        @include bodyBig();
        @include regularWeight();
        color: $darkSilver;
      }

      :not(p) {
        @include semiboldWeight();
        color: $cyanAzure;
      }
    }
  }
}